/* Magnific Popup CSS */
@import url(jquery-ui-slider/jquery-ui.css);
@import url(jquery-ui-slider/jquery-ui.theme.css);
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panel.mm-highest {
  z-index: 1;
}

.mm-panel.mm-noanimation {
  -webkit-transition: none !important;
  transition: none !important;
}

.mm-panel.mm-noanimation.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  box-sizing: border-box;
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after, .mm-prev:before {
  content: '';
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview a, .mm-listview a:hover {
  text-decoration: none;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  content: '';
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.mm-listview > li:not(.mm-divider):after {
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  content: '';
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px;
}

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu .mm-divider {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking {
  overflow: hidden;
}

html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
}

.mm-menu.mm-offcanvas.mm-opened {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
  z-index: 10;
}

.mm-menu.mm-offcanvas {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening .mm-menu ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

.mm-menu .mm-listview.mm-border-none > li:after, .mm-menu .mm-listview > li.mm-border-none:after, .mm-menu.mm-border-none .mm-listview > li:after {
  content: none;
}

.mm-menu .mm-listview.mm-border-full > li:after, .mm-menu .mm-listview > li.mm-border-full:after, .mm-menu.mm-border-full .mm-listview > li:after {
  left: 0 !important;
}

.mm-menu .mm-listview.mm-border-offset > li:after, .mm-menu .mm-listview > li.mm-border-offset:after, .mm-menu.mm-border-offset .mm-listview > li:after {
  right: 20px;
}

.mm-menu.mm-fx-menu-zoom {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

html.mm-opening .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
}

html.mm-right.mm-opening .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu.mm-fx-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

html.mm-opening .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

html.mm-right.mm-opening .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-fx-menu-fade {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

html.mm-opening .mm-menu.mm-fx-menu-fade {
  opacity: 1;
}

.mm-menu .mm-fx-panels-none.mm-panel, .mm-menu.mm-fx-panels-none .mm-panel {
  -webkit-transition-property: none;
  transition-property: none;
}

.mm-menu .mm-fx-panels-none.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-none .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-zoom.mm-panel, .mm-menu.mm-fx-panels-zoom .mm-panel {
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
}

.mm-menu .mm-fx-panels-zoom.mm-panel.mm-opened, .mm-menu.mm-fx-panels-zoom .mm-panel.mm-opened {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-zoom.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-zoom .mm-panel.mm-subopened {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
}

.mm-menu .mm-fx-panels-slide-0.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-0 .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-slide-100.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-100 .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu .mm-fx-panels-slide-up.mm-panel, .mm-menu.mm-fx-panels-slide-up .mm-panel {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.mm-menu .mm-fx-panels-slide-up.mm-panel.mm-opened, .mm-menu.mm-fx-panels-slide-up .mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-slide-up.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-up .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(1) {
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(2) {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(3) {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(4) {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(5) {
  -webkit-transition-delay: 250ms;
  transition-delay: 250ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(6) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(7) {
  -webkit-transition-delay: 350ms;
  transition-delay: 350ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(8) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(9) {
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(10) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(11) {
  -webkit-transition-delay: 550ms;
  transition-delay: 550ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(12) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(13) {
  -webkit-transition-delay: 650ms;
  transition-delay: 650ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(14) {
  -webkit-transition-delay: .7s;
  transition-delay: .7s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(15) {
  -webkit-transition-delay: 750ms;
  transition-delay: 750ms;
}

.mm-menu.mm-fx-listitems-slide .mm-listview > li {
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
  opacity: 0;
}

html.mm-opening .mm-menu.mm-fx-listitems-slide .mm-panel.mm-opened .mm-listview > li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.mm-menu.mm-fx-listitems-fade .mm-listview > li {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 0;
}

html.mm-opening .mm-menu.mm-fx-listitems-fade .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
}

.mm-menu.mm-fx-listitems-drop .mm-listview > li {
  -webkit-transition-property: opacity,top;
  transition-property: opacity,top;
  opacity: 0;
  top: -25%;
}

html.mm-opening .mm-menu.mm-fx-listitems-drop .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
  top: 0;
}

.mm-menu.mm-offcanvas.mm-fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px;
}

html.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(10000px, 0);
    -ms-transform: translate(10000px, 0);
    transform: translate(10000px, 0);
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-right.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-right.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(-10000px, 0);
    -ms-transform: translate(-10000px, 0);
    transform: translate(-10000px, 0);
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0);
  }
}

.mm-menu.mm-offcanvas.mm-fullscreen.mm-bottom, .mm-menu.mm-offcanvas.mm-fullscreen.mm-top {
  height: 100%;
  min-height: 140px;
  max-height: 10000px;
}

html.mm-iconbar body {
  overflow-x: hidden;
}

html.mm-iconbar .mm-page {
  background: inherit;
  min-height: 100vh;
}

html.mm-iconbar .mm-slideout {
  box-sizing: border-box;
  padding-right: 60px;
  -webkit-transform: translate(60px, 0);
  -ms-transform: translate(60px, 0);
  transform: translate(60px, 0);
  -webkit-transform: translate3d(60px, 0, 0);
  transform: translate3d(60px, 0, 0);
}

.mm-menu.mm-iconbar {
  display: block;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify:after, .mm-menu .mm-panels > .mm-panel.mm-listview-justify:before, .mm-menu.mm-listview-justify .mm-panels > .mm-panel:after, .mm-menu.mm-listview-justify .mm-panels > .mm-panel:before {
  content: none;
  display: none;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-height: 40px;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li:not(.mm-divider), .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li > a:not(.mm-next), .mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li > span, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li > a:not(.mm-next), .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li > span {
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mm-listview-small .mm-listview > li > a:not(.mm-next), .mm-listview-small .mm-listview > li > span {
  padding: 7px 10px 7px 20px;
}

.mm-listview-small .mm-listview > li.mm-vertical > .mm-next, .mm-listview-small .mm-vertical > .mm-listview > li > .mm-next {
  height: 34px;
}

.mm-listview-large .mm-listview > li > a:not(.mm-next), .mm-listview-large .mm-listview > li > span {
  padding: 15px 10px 15px 20px;
}

.mm-listview-large .mm-listview > li.mm-vertical > .mm-next, .mm-listview-large .mm-vertical > .mm-listview > li > .mm-next {
  height: 50px;
}

.mm-listview-huge .mm-listview > li > a:not(.mm-next), .mm-listview-huge .mm-listview > li > span {
  padding: 20px 10px 20px 20px;
}

.mm-listview-huge .mm-listview > li.mm-vertical > .mm-next, .mm-listview-huge .mm-vertical > .mm-listview > li > .mm-next {
  height: 60px;
}

.mm-listview .mm-divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-listview .mm-inset {
  list-style: inside disc;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-menu .mm-listview.mm-multiline > li > a, .mm-menu .mm-listview.mm-multiline > li > span, .mm-menu .mm-listview > li.mm-multiline > a, .mm-menu .mm-listview > li.mm-multiline > span, .mm-menu.mm-multiline .mm-listview > li > a, .mm-menu.mm-multiline .mm-listview > li > span {
  text-overflow: clip;
  white-space: normal;
}

.mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  opacity: 0;
}

html.mm-opening .mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s;
}

.mm-menu.mm-opened.mm-pagedim ~ #mm-blocker {
  background: inherit;
}

.mm-menu.mm-opened.mm-pagedim-white ~ #mm-blocker {
  background: #fff;
}

.mm-menu.mm-opened.mm-pagedim-black ~ #mm-blocker {
  background: #000;
}

.mm-menu.mm-popup {
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.mm-menu.mm-popup.mm-opened ~ .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

.mm-menu.mm-popup.mm-opened ~ #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-opening .mm-menu.mm-popup {
  opacity: 1;
}

.mm-menu.mm-offcanvas.mm-right {
  left: auto;
}

html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-front #mm-blocker {
  z-index: 1;
}

html.mm-front .mm-menu.mm-offcanvas {
  z-index: 2;
}

.mm-menu.mm-offcanvas.mm-front, .mm-menu.mm-offcanvas.mm-next {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-front.mm-right, .mm-menu.mm-offcanvas.mm-next.mm-right {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-top {
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.mm-menu.mm-offcanvas.mm-bottom {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.mm-menu.mm-offcanvas.mm-bottom {
  top: auto;
}

html.mm-opening .mm-menu.mm-offcanvas.mm-front, html.mm-opening .mm-menu.mm-offcanvas.mm-next {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  height: 80%;
  min-height: 140px;
  max-height: 880px;
}

.mm-menu.mm-shadow-page:after {
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 100;
}

.mm-menu.mm-shadow-page.mm-right:after {
  left: auto;
  right: 100%;
}

.mm-menu.mm-shadow-page.mm-front:after, .mm-menu.mm-shadow-page.mm-next:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-page:after {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-dark {
  background: #333;
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-navbar a, .mm-menu.mm-theme-dark .mm-navbar > * {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-btn:after, .mm-menu.mm-theme-dark .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview {
  border-color: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-dark .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu.mm-theme-dark .mm-divider {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu.mm-theme-dark label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-fixeddivider span {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-shadow-page.mm-theme-dark:after {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.mm-menu.mm-shadow-panels.mm-theme-dark .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.mm-menu.mm-theme-dark .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-indexer a {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark label.mm-toggle {
  background: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark label.mm-toggle:before {
  background: #333;
}

.mm-menu.mm-theme-dark input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-white {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-navbar a, .mm-menu.mm-theme-white .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-btn:after, .mm-menu.mm-theme-white .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  tap-highlight-color: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-white .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-divider {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-shadow-page.mm-theme-white:after {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mm-menu.mm-shadow-panels.mm-theme-white .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mm-menu.mm-theme-white .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-indexer a {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white label.mm-toggle:before {
  background: #fff;
}

.mm-menu.mm-theme-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-black {
  background: #000;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-navbar a, .mm-menu.mm-theme-black .mm-navbar > * {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-btn:after, .mm-menu.mm-theme-black .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview {
  border-color: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  tap-highlight-color: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-black .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black .mm-divider {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-fixeddivider span {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-shadow-page.mm-theme-black:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-panels.mm-theme-black .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: false;
}

.mm-menu.mm-theme-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-indexer a {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-toggle:before {
  background: #000;
}

.mm-menu.mm-theme-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu .mm-tileview.mm-listview:after, .mm-menu.mm-tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both;
}

.mm-menu .mm-tileview.mm-listview > li, .mm-menu.mm-tileview .mm-listview > li {
  width: 50%;
  height: 0;
  padding: 50% 0 0 0;
  float: left;
  position: relative;
}

.mm-menu .mm-tileview.mm-listview > li:after, .mm-menu.mm-tileview .mm-listview > li:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xs, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-s, .mm-menu.mm-tileview .mm-listview > li.mm-tile-s {
  width: 25%;
  padding-top: 25%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-l, .mm-menu.mm-tileview .mm-listview > li.mm-tile-l {
  width: 75%;
  padding-top: 75%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xl, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xl {
  width: 100%;
  padding-top: 100%;
}

.mm-menu .mm-tileview.mm-listview > li > a, .mm-menu .mm-tileview.mm-listview > li > span, .mm-menu.mm-tileview .mm-listview > li > a, .mm-menu.mm-tileview .mm-listview > li > span {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0 10px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next, .mm-menu.mm-tileview .mm-listview > li > .mm-next {
  width: auto;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next:after, .mm-menu .mm-tileview.mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-listview > li > .mm-next:after, .mm-menu.mm-tileview .mm-listview > li > .mm-next:before {
  content: none;
  display: none;
}

.mm-menu.mm-tileview .mm-panel {
  padding-left: 0;
  padding-right: 0;
}

.mm-menu.mm-tileview .mm-panel:after {
  content: none;
  display: none;
}

.mm-menu.mm-tileview .mm-listview {
  margin: 0;
}

html.mm-widescreen body {
  position: relative;
}

html.mm-widescreen #mm-blocker {
  display: none !important;
}

html.mm-widescreen .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  width: 70% !important;
  margin-left: 30% !important;
}

html.mm-widescreen .mm-page {
  background: inherit;
  box-sizing: border-box;
}

html.mm-widescreen.mm-blocking, html.mm-widescreen.mm-blocking body {
  overflow: auto;
}

.mm-menu.mm-widescreen {
  border-right-width: 1px;
  border-right-style: solid;
  display: block !important;
  width: 30% !important;
  min-width: 0 !important;
  max-width: none !important;
  top: 0 !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 100 !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-menu.mm-widescreen.mm-pageshadow:after {
  content: none;
  display: none;
}

.mm-menu.mm-autoheight {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
  -webkit-transition-property: height,-webkit-transform;
  transition-property: height,-webkit-transform;
  transition-property: transform,height;
  transition-property: transform,height,-webkit-transform;
}

.mm-menu.mm-autoheight:not(.mm-offcanvas) {
  position: relative;
}

.mm-menu.mm-measureheight .mm-panel.mm-vertical.mm-opened, .mm-menu.mm-measureheight .mm-panel:not(.mm-vertical) {
  display: block !important;
}

.mm-menu.mm-measureheight .mm-panels > .mm-panel {
  bottom: auto !important;
  height: auto !important;
}

.mm-columns {
  -webkit-transition-property: width;
  transition-property: width;
}

.mm-columns .mm-panels > .mm-panel {
  right: auto;
  -webkit-transition-property: width,-webkit-transform;
  transition-property: width,-webkit-transform;
  transition-property: width,transform;
  transition-property: width,transform,-webkit-transform;
}

.mm-columns .mm-panels > .mm-panel.mm-opened, .mm-columns .mm-panels > .mm-panel.mm-subopened {
  border-left: 1px solid;
  border-color: inherit;
  display: block !important;
}

.mm-columns .mm-panels > .mm-columns-0 {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-columns-0 .mm-panels > .mm-panel {
  z-index: 0;
}

.mm-columns-0 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-0 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-0 {
  width: 80%;
  min-width: 140px;
  max-width: 0;
}

html.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-1 {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-columns-1 .mm-panels > .mm-panel {
  z-index: 1;
  width: 100%;
}

.mm-columns-1 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-1 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-1 {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-2 {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-columns-2 .mm-panels > .mm-panel {
  z-index: 2;
  width: 50%;
}

.mm-columns-2 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-2 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-2 {
  width: 80%;
  min-width: 140px;
  max-width: 880px;
}

html.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(880px, 0);
    -ms-transform: translate(880px, 0);
    transform: translate(880px, 0);
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(-880px, 0);
    -ms-transform: translate(-880px, 0);
    transform: translate(-880px, 0);
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-3 {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-columns-3 .mm-panels > .mm-panel {
  z-index: 3;
  width: 33.34%;
}

.mm-columns-3 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-3 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-3 {
  width: 80%;
  min-width: 140px;
  max-width: 1320px;
}

html.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(1320px, 0);
    -ms-transform: translate(1320px, 0);
    transform: translate(1320px, 0);
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(-1320px, 0);
    -ms-transform: translate(-1320px, 0);
    transform: translate(-1320px, 0);
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-4 {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-columns-4 .mm-panels > .mm-panel {
  z-index: 4;
  width: 25%;
}

.mm-columns-4 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-4 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-4 {
  width: 80%;
  min-width: 140px;
  max-width: 1760px;
}

html.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(1760px, 0);
    -ms-transform: translate(1760px, 0);
    transform: translate(1760px, 0);
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(-1760px, 0);
    -ms-transform: translate(-1760px, 0);
    transform: translate(-1760px, 0);
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0);
  }
}

.mm-columns.mm-offcanvas.mm-bottom, .mm-columns.mm-offcanvas.mm-top {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

html.mm-opening .mm-columns.mm-offcanvas.mm-front, html.mm-opening .mm-columns.mm-offcanvas.mm-next {
  -webkit-transition-property: width,min-width,max-width,-webkit-transform;
  transition-property: width,min-width,max-width,-webkit-transform;
  transition-property: width,min-width,max-width,transform;
  transition-property: width,min-width,max-width,transform,-webkit-transform;
}

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%;
}

em.mm-counter + a.mm-next {
  width: 90px;
}

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
  margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0;
}

em.mm-counter + a.mm-fullsubopen + a, em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a, .mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
  display: none;
}

.mm-menu em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-divider > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  line-height: 25px;
}

.mm-divider.mm-opened a.mm-next:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-collapsed:not(.mm-uncollapsed) {
  display: none;
}

.mm-fixeddivider {
  background: inherit;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-fixeddivider:after {
  content: none !important;
  display: none !important;
}

.mm-hasdividers .mm-fixeddivider {
  display: block;
}

.mm-menu .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05);
}

html.mm-opened.mm-dragging .mm-menu, html.mm-opened.mm-dragging .mm-slideout {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.mm-menu.mm-dropdown {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
}

html.mm-dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-dropdown #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-dropdown .mm-menu {
  z-index: 2;
}

html.mm-dropdown.mm-opened:not(.mm-opening) .mm-menu {
  display: none;
}

.mm-menu.mm-tip:before {
  content: '';
  background: inherit;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-menu.mm-tipleft:before {
  left: 22px;
}

.mm-menu.mm-tipright:before {
  right: 22px;
}

.mm-menu.mm-tiptop:before {
  top: -8px;
}

.mm-menu.mm-tipbottom:before {
  bottom: -8px;
}

.mm-iconpanel .mm-panels > .mm-panel {
  -webkit-transition-property: left,-webkit-transform;
  transition-property: left,-webkit-transform;
  transition-property: transform,left;
  transition-property: transform,left,-webkit-transform;
}

.mm-iconpanel .mm-panels > .mm-panel.mm-opened, .mm-iconpanel .mm-panels > .mm-panel.mm-subopened {
  border-left: 1px solid;
  border-color: inherit;
  display: block !important;
}

.mm-iconpanel .mm-panels > .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  left: 0;
}

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  left: 40px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  left: 80px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  left: 120px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  left: 160px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  left: 200px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  left: 240px;
}

.mm-subblocker {
  background: inherit;
  opacity: 0;
  display: block;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.mm-subopened .mm-subblocker {
  opacity: .6;
  bottom: -100000px;
}

.mm-keyboardfocus a:focus {
  outline: 0;
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.5);
}

.mm-navbars-bottom, .mm-navbars-top {
  background: inherit;
  border-color: inherit;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.mm-navbars-bottom > .mm-navbar, .mm-navbars-top > .mm-navbar {
  border: none;
  padding: 0;
  position: relative;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-navbars-top {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 0;
}

.mm-navbars-bottom {
  border-top-style: solid;
  border-top-width: 1px;
  bottom: 0;
}

.mm-navbar.mm-hasbtns {
  padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left;
}

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
}

.mm-hasnavbar-top-1 .mm-panels {
  top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels {
  top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels {
  top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px;
}

.mm-hasnavbar-bottom-1 .mm-panels {
  bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels {
  bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels {
  bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px;
}

.mm-navbar-size-2 {
  height: 80px;
}

.mm-navbar-size-3 {
  height: 120px;
}

.mm-navbar-size-4 {
  height: 160px;
}

.mm-navbar-content-2 > * {
  width: 50%;
}

.mm-navbar-content-3 > * {
  width: 33.33%;
}

.mm-navbar-content-4 > * {
  width: 25%;
}

.mm-navbar-content-5 > * {
  width: 20%;
}

.mm-navbar-content-6 > * {
  width: 16.67%;
}

.mm-menu.mm-rtl {
  direction: rtl;
}

.mm-menu.mm-rtl.mm-offcanvas {
  right: auto;
}

.mm-menu.mm-rtl .mm-panel:not(.mm-opened) {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-rtl .mm-panel.mm-subopened {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:first-child {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:last-child {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-navbar .mm-prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-menu.mm-rtl .mm-listview > li:not(.mm-divider)::after {
  left: 0;
  right: 20px;
}

.mm-menu.mm-rtl .mm-listview > li > a:not(.mm-next), .mm-menu.mm-rtl .mm-listview > li > span:not(.mm-next) {
  padding-left: 10px;
  padding-right: 20px !important;
  margin-right: 0 !important;
}

.mm-menu.mm-rtl .mm-listview .mm-next {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:before {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-listview .mm-next + a, .mm-menu.mm-rtl .mm-listview .mm-next + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter {
  left: 45px;
  right: auto;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + span {
  padding-left: 90px;
}

.mm-menu.mm-rtl label.mm-check, .mm-menu.mm-rtl label.mm-toggle {
  left: 20px;
  right: auto !important;
}

.mm-menu.mm-rtl label.mm-toggle + a, .mm-menu.mm-rtl label.mm-toggle + span {
  padding-left: 80px;
}

.mm-menu.mm-rtl label.mm-check + a, .mm-menu.mm-rtl label.mm-check + span {
  padding-left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check, .mm-menu.mm-rtl a.mm-next + label.mm-toggle {
  left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  padding-left: 70px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle {
  left: 100px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + span, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-] {
  left: 0;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-].mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel {
  -webkit-transition-property: right,-webkit-transform;
  transition-property: right,-webkit-transform;
  transition-property: transform,right;
  transition-property: transform,right,-webkit-transform;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  right: 0;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  right: 40px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  right: 80px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  right: 120px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  right: 160px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  right: 200px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  right: 240px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-opened {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit;
}

.mm-search, .mm-search input {
  box-sizing: border-box;
}

.mm-search {
  height: 40px;
  padding: 7px 10px 0 10px;
  position: relative;
}

.mm-search input {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  font: inherit;
  font-size: 14px;
  line-height: 26px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  margin: 0;
  padding: 0 10px;
}

.mm-search input::-ms-clear {
  display: none;
}

.mm-search .mm-clear, .mm-search .mm-next {
  right: 0;
}

.mm-panel > .mm-search {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-panel.mm-hassearch {
  padding-top: 40px;
}

.mm-panel.mm-hassearch.mm-hasnavbar {
  padding-top: 80px;
}

.mm-panel.mm-hassearch.mm-hasnavbar > .mm-search {
  top: 40px;
}

.mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  padding: 40px 0;
}

.mm-noresults .mm-indexer {
  display: none !important;
}

li.mm-nosubresults > a.mm-next {
  display: none;
}

li.mm-nosubresults > a.mm-next + a, li.mm-nosubresults > a.mm-next + span {
  padding-right: 10px;
}

.mm-menu .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-indexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  z-index: 15;
  -webkit-transition: right .4s ease;
  transition: right .4s ease;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-indexer a {
  text-decoration: none;
  display: block;
  height: 3.85%;
}

.mm-indexer ~ .mm-panel.mm-hasindexer {
  padding-right: 40px;
}

.mm-hasindexer .mm-indexer {
  right: 0;
}

.mm-hasindexer .mm-fixeddivider {
  right: 20px;
}

.mm-menu .mm-indexer a {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
  -webkit-transition: background .4s ease;
  transition: background .4s ease;
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.5);
}

input.mm-check, input.mm-toggle {
  position: absolute;
  left: -10000px;
}

label.mm-check, label.mm-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 2;
}

label.mm-check:before, label.mm-toggle:before {
  content: '';
  display: block;
}

label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin-top: -15px;
}

label.mm-toggle:before {
  border-radius: 30px;
  width: 28px;
  height: 28px;
  margin: 1px;
}

input.mm-toggle:checked ~ label.mm-toggle:before {
  float: right;
}

label.mm-check {
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

label.mm-check:before {
  border-left: 3px solid;
  border-bottom: 3px solid;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  opacity: .1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input.mm-check:checked ~ label.mm-check:before {
  opacity: 1;
}

li.mm-vertical label.mm-check, li.mm-vertical label.mm-toggle {
  bottom: auto;
  margin-top: 0;
}

li.mm-vertical label.mm-toggle {
  top: 5px;
}

li.mm-vertical label.mm-check {
  top: 5px;
}

label.mm-check, label.mm-toggle {
  right: 20px;
}

label.mm-toggle + a, label.mm-toggle + span {
  padding-right: 80px;
}

label.mm-check + a, label.mm-check + span {
  padding-right: 60px;
}

a.mm-next + label.mm-check, a.mm-next + label.mm-toggle {
  right: 60px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span, a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  margin-right: 50px;
}

a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  padding-right: 70px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span {
  padding-right: 50px;
}

em.mm-counter + a.mm-next + label.mm-check, em.mm-counter + a.mm-next + label.mm-toggle {
  right: 100px;
}

em.mm-counter + a.mm-next + label.mm-check + a, em.mm-counter + a.mm-next + label.mm-check + span, em.mm-counter + a.mm-next + label.mm-toggle + a, em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-right: 90px;
}

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu label.mm-toggle:before {
  background: #f3f3f3;
}

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.75);
}
